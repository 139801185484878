.container {
  min-height: 100%;
  background-color: #f7f8fa;
}

.groupInfo {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeCircleOutlined {
  font-size: 22px;
  position: absolute;
  right: 0;
}

.imgCard {
  position: relative;
  margin-right: 12px;
  margin-bottom: 12px;
  width: 102px;
  height: 102px;
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;

  > div {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;

    .img {
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
      user-select: none;
    }
  }

  .drag {
    width: 30px !important;
    height: 30px !important;
    position: absolute;
    right: -11px;
    top: -11px;
    z-index: 9999;
  }
}

.cardList {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.imageList {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.row {
  position: relative;
}

.icon {
  font-size: 16px;
}

.formItemMargin {
  margin-bottom: 0 !important;
}

.formItem {
  width: 100%;

  :global {
    .ant-form-item-control-input-content {
      display: flex;
      align-items: center;
    }
  }
}

.input {
  margin-left: 8px;
}

.add {
  margin-left: 8px;
  width: 130px !important;
}

.addCard {
  position: absolute;
  right: -138px;
  top: 0;
}

.remove {
  margin-left: 8px;
}

.type {
  display: flex;
  position: relative;
}

:global {
  .ant-descriptions-item-content .avatar .ant-image-mask {
    border-radius: 50%;
  }
}
